<template>
  <div class="custom-card">
    <div class="titulo-pagina">
      {{ $t('modulos.kanban_em_execucao.titulos.titulo') }}
    </div>
    <div class="inputs-container">
      <div class="item">
        <input-setor
          v-model="setor"
          class="  input-setor-modal"
          :placeholder="$t('geral.inputs.selecione')"
          :participante-id="participanteSelecionado"
          :multiplos="false"
          flagParticipanteComCalibracaoOuManutencao
          @emiterEvento="eventoHandler"
          :label="$t('modulos.kanban_em_execucao.cabecalho.setor')"
        />
      </div>
      <div class="item situacao">
        <input-text
          class="  input-setor-modal"
          value="Em execução"
          disabled
          :label="$t('modulos.kanban_em_execucao.cabecalho.situacao')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SetorService from '@common/services/cadastros/SetorService';
import { DropdownModel } from '../../../../../common/models/geral/DropdownModel';
import { mapGetters } from 'vuex';
import { InputSetor } from '@components/inputs';

export default {
  components: {
    InputSetor,
  },
  name: 'Cabecalho',
  props: {
    loading: { type: Boolean, default: false },
    mostrarConfiguracao: { type: Boolean, default: true },
    listaAtualizadaEm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      setorId: null,
      setor: null,
      options: {
        setores: [],
      },
    };
  },

  watch: {
    setor() {
      this.atualizaProgramacao();
    },
  },
  methods: {
    eventoHandler(value) {
      this.setor = { ...value };
    },
    atualizaProgramacao() {
      this.$emit('atualizaSetor', this.setor);
    },
    setorInicialmenteSelecionado() {
      if (this.usuario.setorId) {
        this.setorId = this.usuario.setorId;
        this.setor = this.options.setores.find(
          (setor) => setor.value === this.setorId
        );
        if (this.setor) return;
      }

      this.setorId = this.options.setores[0];
      this.setor = this.options.setores[0];
    },
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteSelecionado']),
    ...mapGetters('Autenticacao', ['usuario']),
  },

  mounted() {
    const parametros = {};

    if (this.participanteId)
      parametros.participanteId = this.participanteSelecionado;

    SetorService.listarSetoresParticipantesContaCalibracaoOuManutencao(
      parametros
    ).then((response) => {
      this.options.setores = new DropdownModel(response.data.items);
      this.setorInicialmenteSelecionado();
    });
  },
};
</script>

<style scoped lang="scss">
.inputs-container {
  display: flex;
  margin-top: 8px;
}

.item {
  width: 350px;  
  box-sizing: border-box;  
}

.input-situacao {
  width: 100%;
}
.titulo,
.text {
  font-size: 1.2rem;
  opacity: 0.9;

  display: flex;
  margin-right: 4px;
  margin-left: 4px;
}
.input-setor-modal {
  padding: 0 !important;
  flex: 1 !important;
  max-width: 100% !important;
  width: 100%;
}
.titulo-pagina {
  color: #344054;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 20px;
  line-height: 32px;
}
.input-situacao {
  width: 398px;
  margin-left: 8px;
}

.situacao{
  margin-left: 8px;
}
 

@media (min-width: 960px) {
  .col-md-2 {
    max-width: 100% !important;
    flex: none;
  }
  .col-12 {
    max-width: 100% !important;
  }
}
@media (max-width: 960px) {
  .col-md-2 {
    max-width: 100% !important;
    flex: none;
  }
  .col-12 {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 960px) {
  .item {
    flex-direction: column;
    align-items: start;
    height: auto;
  }

  .text {
    margin-right: 0;
    margin-left: 0;
  }
  .container-update {
    margin: 12px 0 12px;
  }
  .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 660px) {
  .inputs-container {
    flex-direction: column;
  }
  .item{
    width: 100%;
  }
  .situacao{
    margin-left: 0;
  }
   
}
</style>
