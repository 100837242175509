<template>
  <div>
    <Cabecalho style="margin-bottom: 3rem" @atualizaSetor="atualizaSetor" />
    <div class="sequencia">
      <tabela-padrao-prime-vue
        ref="tabela"
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        :mostrar-seletor="false"
        :mostrar-acoes="false"
        @paginar="listarRegistros"
        @nova-ordenacao="ordenacaoColuna"
      >
        >
      </tabela-padrao-prime-vue>
    </div>
  </div>
</template>
<script>
import KanbanEmExecucaoService from '@common/services/cadastros/KanbanEmExecucaoService.js';
import Cabecalho from './components/Cabecalho.vue';
import helpers from '@/common/utils/helpers';

export default {
  components: { Cabecalho },
  data() {
    return {
      setorId: null,
      setor: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nomeColaborador',
            text: this.$t('modulos.kanban_em_execucao.tabela.colaborador'),
            sortable: false,
          },
          {
            value: 'ipColaborador',
            text: this.$t('modulos.kanban_em_execucao.tabela.ipColaborador'),
            sortable: true,
            width: 50,
          },
          {
            value: 'codigoOS',
            text: this.$t('modulos.kanban_em_execucao.tabela.os'),
            sortable: true,
          },
          {
            value: 'codigoInstrumento',
            text: this.$t('modulos.kanban_em_execucao.tabela.instrumento'),
            sortable: true,
          },
          {
            value: 'nomeInstrumento',
            text: this.$t('modulos.kanban_em_execucao.tabela.tipoInstrumento'),
            sortable: true,
          },
          {
            value: 'tempoPadrao',
            text: this.$t('modulos.kanban_em_execucao.tabela.tempoPadrao'),
            sortable: true,
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.kanban_em_execucao.tabela.tempoEmExecucao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  watch: {
    setorId() {
      this.listarRegistros();
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'EmExecucao', 'Listar');
  },

  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.kanban_em_execucao.titulos.listagem')
    );
  },
  methods: {
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      const parametros = {
        setorId: this.setorId,
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      KanbanEmExecucaoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    atualizaSetor: function ({ value, text }) {
      this.setorId = value;
      this.setor = text;
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>

<style>
.sequencia
  > div
  > .p-datatable-wrapper
  > .p-datatable-table
  .p-resizable-column:nth-child(2) {
  width: 30rem !important;
}

.sequencia
  > div
  > div
  > .p-datatable-wrapper
  > .p-datatable-table
  .p-datatable-tbody
  > tr
  > td {
  padding: 0.4rem !important;
}
</style>
